import RehauStore from "@/store/lib";
import { Messages } from '@/constants';
import {
  RequestsService,
  DealersService,
  DealerCertificatesService,
  AdminService,
} from "@/services";

const adminService = new AdminService();
const requestsService = new RequestsService()
const dealersService = new DealersService()
const dealerCertificatesService = new DealerCertificatesService()

const requests = new RehauStore({
  state: {
    requests: [],
    requestsTotal: null,
    request: {},
    requestStatuses: [],
    requestStatusesTotal: null,
    manufacturerRejectedRequestData: null,
    requestData: null,
    partnersByInn: {},
    partnerExists: false,
    showAddNewPartnerForm: false,
  }
})
.action("fetchRequests", {
  serviceFn: requestsService.fetchRequests,
  onSuccess: (state, {data}) => {
    state.requests = data.items
    state.requestsTotal = data.totalItems
  }
})
.action("deleteRequest", {
  serviceFn: adminService.deleteRequest,
  onSuccess: (state, { context }) => {
    context.dispatch("showSuccessAlert", Messages.Success.RequestDelete, { root: true });
  },
  onError: (state, { error, context }) => {
    context.dispatch("showErrorAlert", error, { root: true });
  }
})
.action("fetchManufacturerRequestData", {
  serviceFn: requestsService.getManufacturerRequestData,
  property: "manufacturerRejectedRequestData"
})
.action("editManufacturerRequestData", {
  serviceFn: requestsService.editManufacturerRequestData,
  onSuccess: (state, {params, context}) => {
    context.dispatch("fetchManufacturerRequestData", params.id);
    context.dispatch("showSuccessAlert", Messages.Success.RequestEdited, {root: true});
  },
  onError: (state, {error, context}) => {
    context.dispatch("showErrorAlert", error, {root: true});
  }
})
.action("fetchDealersByInn", {
  serviceFn: dealersService.getDealersList,
  onSuccess: (state, {data}) => {
    state.partnersByInn = data
    state.partnerExists = data.length;
    state.showAddNewPartnerForm = !data.length;
  }
})
.action("fetchRequestSelfRetail", {
  serviceFn: requestsService.fetchRequestSelfRetail,
  property: "requestData"
})
.action("editRequestSelfRetail", {
  serviceFn: requestsService.editRequestSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.EditRequestSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.EditRequestSelfRetail, {root: true});
  }
})
.action("fetchRequest", {
  serviceFn: requestsService.fetchRequest,
  property: "request"
})
.action("fetchRequestStatuses", {
  serviceFn: requestsService.fetchRequestStatuses,
  onSuccess: (state, {data}) => {
    console.log(data);
    state.requestStatuses = data.items;
    state.requestStatusesTotal = data.totalItems;
  }
})
.action("confirmLeader", {
  serviceFn: dealersService.confirmLeader,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestConfirmed, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {root: true});
  }
})
.action("confirmFirstManager", {
  serviceFn: dealersService.confirmFirstManager,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestConfirmed, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {root: true});
  }
})
.action("rejectManager", {
  serviceFn: dealersService.rejectManager,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("rejectManagerForCorrection", {
  serviceFn: dealersService.rejectManagerForCorrection,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("rejectLeader", {
  serviceFn: dealersService.rejectLeader,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("rejectLeaderForCorrection", {
  serviceFn: dealersService.rejectLeaderForCorrection,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("confirmDealerCertificateByManager", {
  serviceFn: dealerCertificatesService.managerConfirmDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestConfirmed, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {root: true});
  }
})
.action("rejectDealerCertificateByManager", {
  serviceFn: dealerCertificatesService.managerRejectDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("confirmDealerCertificateByLeader", {
  serviceFn: dealerCertificatesService.leaderConfirmDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestConfirmed, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {root: true});
  }
})
.action("rejectDealerCertificateByLeader", {
  serviceFn: dealerCertificatesService.leaderRejectDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})
.action("managerApproveAddSelfRetail", {
  serviceFn: requestsService.managerApproveAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.ApproveAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveAddSelfRetail, {root: true});
  }
})
.action("managerRejectAddSelfRetail", {
  serviceFn: requestsService.managerRejectAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectAddSelfRetail, {root: true});
  }
})
.action("managerRejectForCorrectionAddSelfRetail", {
  serviceFn: requestsService.managerRejectForCorrectionAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectForCorrectionAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectForCorrectionAddSelfRetail, {root: true});
  }
})
.action("leaderApproveAddSelfRetail", {
  serviceFn: requestsService.leaderApproveAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.ApproveAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveAddSelfRetail, {root: true});
  }
})
.action("leaderRejectAddSelfRetail", {
  serviceFn: requestsService.leaderRejectAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectAddSelfRetail, {root: true});
  }
})
.action("leaderRejectForCorrectionAddSelfRetail", {
  serviceFn: requestsService.leaderRejectForCorrectionAddSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectForCorrectionAddSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectForCorrectionAddSelfRetail, {root: true});
  }
})
.action("managerApproveCertificationSelfRetail", {
  serviceFn: requestsService.managerApproveCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.ApproveCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveCertificationSelfRetail, {root: true});
  }
})
.action("managerRejectCertificationSelfRetail", {
  serviceFn: requestsService.managerRejectCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectCertificationSelfRetail, {root: true});
  }
})
.action("leaderApproveCertificationSelfRetail", {
  serviceFn: requestsService.leaderApproveCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.ApproveCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveCertificationSelfRetail, {root: true});
  }
})
.action("leaderRejectCertificationSelfRetail", {
  serviceFn: requestsService.leaderRejectCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RejectCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectCertificationSelfRetail, {root: true});
  }
})
.getStore()

export default requests;