import { HttpService } from "../HttpService";

export class AdminService extends HttpService {

  fetchUsers = async (params) => {
    return await this._$get("/admin/users", { params: params }).catch((err) => console.error(`$UserService Error::${err}`));
  };

  fetchRoles = async () => {
    return await this._$get("/admin/roles").catch((err) => console.error(`$UserService Error::${err}`));
  };

  addUser = async (payload) => {
    return await this._$post("/admin/users/create", { body: payload }).catch((err) => console.error(`$UserService Error::${err}`));
  };

  updateUser = async ({ id, payload }) => {
    return await this._$put(`/admin/users/${id}`, { body: payload }).catch((err) => console.error(`$UserService Error::${err}`));
  };

  checkPasswordToken = async (payload) => {
    return await this._$post("/admin/users/checkpasswordtoken", { body: payload }).catch((err) => console.error(`$UserService Error::${err}`));
  };

  createPassword = async (payload) => {
    return await this._$post("/admin/users/createpassword", { body: payload }).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteUser = async (id) => {
    return await this._$delete(`/admin/users/${id}`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteDealerCertificate = async ({ dealerId, certificateId }) => {
    return await this._$delete(`/dealercertificates/${dealerId}/certificates/${certificateId}`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteManufacturersCertificate = async ({ manufacturerId, certificateId }) => {
    return await this._$delete(`/manufacturerscertificates/${manufacturerId}/certificates/${certificateId}`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteSelfretailCertificate = async (certificateId) => {
    return await this._$delete(`/selfretailcertificates/${certificateId}/certificate`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteRequest = async (id) => {
    return await this._$delete(`/requests/${id}`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  deleteManufacturersSelfRetail = async (id) => {
    return await this._$delete(`/manufacturers/selfretails/${id}`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  deleteDealersSelfRetail = async (id) => {
    return await this._$delete(`/dealers/address/${id}`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  deletePartner = async (id) => {
    return await this._$delete(`/dealers/${id}`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  deleteManufacturer = async (id) => {
    return await this._$delete(`/manufacturers/${id}`).catch((err) => console.error(`$UserService Error::${err}`));
  };


  blockUser = async (id) => {
    return await this._$put(`/admin/users/${id}/block`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  unblockUser = async (id) => {
    return await this._$put(`/admin/users/${id}/unblock`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  resetPassword = async (id) => {
    return await this._$put(`/admin/users/${id}/resetpassword`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  resetPasswordByEmail = async (email) => {
    return await this._$put(`/account/${email}/resetpassword`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  fetchManagers = async () => {
    return await this._$get(`/admin/users/managers`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  fetchManagersByRegion = async (id) => {
    return await this._$get(`/admin/users/managers?regionId=${id}`).catch((err) => console.error(`$UserService Error::${err}`));
  };

  checkLeadersByRegion = async () => {
    return await this._$get("/admin/users/leaders/check").catch((err) => console.error(`$UserService Error::${err}`));
  };

  unblockCheck = async (id) => {
    return await this._$get(`/admin/users/${id}/unblockcheck`).catch((err) => console.error(`$UserService Error::${err}`));
  };
}