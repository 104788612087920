import RehauStore from "@/store/lib";
import { Messages } from "@/constants";
import { AdminService, ManufacturersService, ManufacturerCertificatesService, SelfRetailCertificatesService } from "@/services";

const adminService = new AdminService()
const manufacturersService = new ManufacturersService()
const manufacturerCertificatesService = new ManufacturerCertificatesService()
const selfretailCertificatesService = new SelfRetailCertificatesService()

const manufacturers = new RehauStore({
  state: {
    managers: [],
    manufacturers: [],
    manufacturersTotal: null,
    usersByRegion: [],
    managersByRegion: [],

    confirmed: false,
    manufacturer: {},

    isInCertificationProcess: false,

    certificatesHistory: [],
    certificateHistoryTotal: null,

    partners: [],
    partnersTotal: null,

    selfretails: [],
    selfretailsTotal: null,
    selfretailsHistory: [],
    selfretailsHistoryTotal: null,

    manufacturerManager: null,

    certifyReReleaseHistory: [],
  },
})
  .action("deleteManufacturersCertificate", {
    serviceFn: adminService.deleteManufacturersCertificate,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.CertificateDelete, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("deleteSelfretailCertificate", {
    serviceFn: adminService.deleteSelfretailCertificate,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.CertificateDelete, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("deleteManufacturer", {
    serviceFn: adminService.deleteManufacturer,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerDelete, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("deleteManufacturersSelfRetail", {
    serviceFn: adminService.deleteManufacturersSelfRetail,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.SelfRetailDelete, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("fetchManagers", {
    serviceFn: adminService.fetchManagers,
    property: "managers",
  })
  .action("fetchManagersByRegion", {
    serviceFn: adminService.fetchManagersByRegion,
    property: "managersByRegion",
  })
  .action("manufacturerVerify", {
    serviceFn: manufacturersService.manufacturerVerify,
    onSuccess: (state, { data }) => {
      state.confirmed = data.confirmed;
    },
    onError: (state) => {
      state.confirmed = false;
    },
  })
  .action("manufacturerDataCorrect", {
    serviceFn: manufacturersService.manufacturerDataConfirm,
    onSuccess: (state, { data, context }) => {
      state.confirmed = data.success;
      if (data.success) {
        context.dispatch("showSuccessAlert", Messages.Success.DataConfirmed, { root: true });
      }
    },
  })
  .action("manufacturerDataWrong", {
    serviceFn: manufacturersService.manufacturerDataWrong,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.MessageSended, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.MessageSended, { root: true });
    },
  })
  .action("editManufacturer", {
    serviceFn: manufacturersService.editManufacturer,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerEdited, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("fetchManufacturers", {
    serviceFn: manufacturersService.fetchManufacturers,
    onSuccess: (state, { data }) => {
      state.manufacturers = data.items;
      state.manufacturersTotal = data.totalItems;
    },
  })
  .action("addManufacturer", {
    serviceFn: manufacturersService.addManufacturer,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerAdded, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("fetchManufacturer", {
    serviceFn: manufacturersService.manufacturerData,
    property: "manufacturer",
  })
  .action("fetchUsersByManufacturerRegion", {
    serviceFn: manufacturersService.fetchUsersByManufacturerRegion,
    property: "usersByRegion",
  })
  .action("isManufacturerInCertificationProcess", {
    serviceFn: manufacturerCertificatesService.isManufacturerInCertifyProcess,
    property: "isInCertificationProcess",
  })
  .action("fetchManufacturerById", {
    serviceFn: manufacturersService.fetchManufacturer,
    property: "manufacturer",
  })
  .action("fetchManufacturerHistory", {
    serviceFn: manufacturerCertificatesService.getManufacturerHistory,
    onSuccess: (state, { data }) => {
      state.certificatesHistory = data.certificatesHistory;
      state.certificateHistoryTotal = data.certificatesHistory.length;
    },
  })
  .action("fetchManufacturerPartners", {
    serviceFn: manufacturersService.fetchManufacturerDealers,
    onSuccess: (state, { data }) => {
      state.partners = data.items;
      state.partnersTotal = data.totalItems;
    },
  })
  .action("fetchManufacturerPartnersWithCertInfo", {
    serviceFn: manufacturersService.fetchManufacturerDealersWithCertInfo,
    onSuccess: (state, { data }) => {
      state.partners = data.items;
      state.partnersTotal = data.totalItems;
    },
  })
  .action("fetchSelfRetails", {
    serviceFn: manufacturersService.fetchSelfRetails,
    onSuccess: (state, { data }) => {
      state.selfretails = data.items;
      state.selfretailsTotal = data.totalItems;
    },
  })
  .action("fetchSelfRetailsHistory", {
    serviceFn: selfretailCertificatesService.fetchSelfRetailCertificates,
    onSuccess: (state, { data }) => {
      state.selfretailsHistory = data.items;
      state.selfretailsHistoryTotal = data.totalItems;
    },
  })
  .action("fetchSelfRetailCertificateHistoryByManufacturerId", {
    serviceFn: manufacturerCertificatesService.getSelfRetailCertificatesByManufacturerId,
    onSuccess: (state, { data }) => {
      state.selfretailsHistory = data;
      state.selfretailsHistoryTotal = data ? data.length : 0;
    },
  })
  .action("changeActiveManufacturerUser", {
    serviceFn: manufacturersService.changeActiveManufacturerUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerEdited, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.ManufacturerNotEdited, { root: true });
    },
  })
  .action("changeManager", {
    serviceFn: manufacturersService.changeManager,
    onSuccess: (state, { data }) => {
      console.log(data);
    },
  })
  .action("revokeCertificate", {
    serviceFn: manufacturerCertificatesService.revocationManufacturer,
    onSuccess: (state, { data, context }) => {
      context.dispatch("isManufacturerInCertificationProcess", data.manufacturerId);
    },
  })
  .action("requestCertificate", {
    serviceFn: manufacturerCertificatesService.certifyManufacturer,
    onSuccess: (state, { params, context }) => {
      context.dispatch("isManufacturerInCertificationProcess", params.manufacturerId);
    },
  })
  .action("selfretailCertificateRevocation", {
    serviceFn: selfretailCertificatesService.selfretailCertificateRevocation,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.SelfRetailCertificateRevocation, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.SelfRetailCertificateRevocation, { root: true });
    },
  })
  .action("changeManufacturerData", {
    serviceFn: manufacturersService.changeManufacturerData,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerEdited, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.ManufacturerEdited, { root: true });
    },
  })
  .action("certifyReRelease", {
    serviceFn: manufacturerCertificatesService.certifyReRelease,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.ManufacturerCertifyReRelease, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("fetchCertifyReReleaseHistory", {
    serviceFn: manufacturerCertificatesService.fetchCertifyReReleaseHistory,
    property: "certifyReReleaseHistory",
    onError: (state, { error, context }) => {
      state.certifyReReleaseHistory = [];
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("fetchSelfretailCertifyReReleaseHistory", {
    serviceFn: selfretailCertificatesService.fetchCertifyReReleaseHistory,
    property: "certifyReReleaseHistory",
    onError: (state, { error, context }) => {
      state.certifyReReleaseHistory = [];
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("editSelfRetail", {
    serviceFn: manufacturersService.updateSelfRetail,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.SelfRetailUpdate, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.SelfRetailUpdate, { root: true });
    },
  })

  // Getters
  .getter("managersDD", (state) => {
    let res = state.managers.map((item) => {
      return { value: item.id, text: `${item.firstName} ${item.lastName}` };
    });
    //res.unshift({ value: null, text: "Менеджер" });
    return res;
  })
  .getStore();

export default manufacturers;