<template>
  <div class="pl-5" v-if="isMounted">
    <div v-if="isCertificationProcess">
      <div class="mb-5 row" v-for="request in certificationRequests" :key="request.id">
        <div class="col">
          <h2 v-if="isManufacturer" class="mb-3 h4">{{ request.initiatorName }} запросил(а) выдачу сертификата.</h2>
          <h2 v-else class="mb-3 h4">
            Производитель {{ request.manufacturerName }}, {{ request.initiatorName }} запросил выдачу сертификата для адреса {{ request.partnerAddress }}
          </h2>
          <b-alert :show="isDealer" variant="danger">
            Данные вашей учетной записи и торговой точки (кроме адреса) Вы можете изменить <b>в личном кабинете после подтверждения.</b><br/>
            Для изменения <b>адреса торговой точки</b> свяжитесь с Производителем.
          </b-alert>
          <br/>

          <b-button @click="$emit('approve-certificate', request.id)" variant="success" class="mr-3">Подтвердить</b-button>
          <b-button @click="$emit('reject-certificate', request.id)" variant="danger">Отклонить</b-button>
          <span class="ml-3 spinner" v-if="loading">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </span>
        </div>
      </div>
    </div>

    <div class="mb-5 row" v-if="showRevoked && !hasActiveRequest">
      <div class="col">
        <p v-if="isManufacturer">
          У вас нет действующего сертификата. Пожалуйста обратитесь к вашему менеджеру для оформления сертификата.
        </p>
        <p v-else-if="isDealer">
          У вас нет действующего сертификата. Пожалуйста обратитесь к вашему производителю для оформления сертификата.
        </p>
        <div v-else-if="isSelfRetail">
          <p>У вас нет действующего сертификата.</p>
          <b-button v-if="!isParentCertificateRevoked" variant="success" @click="$emit('request-certificate')">Запросить сертификат</b-button>
        </div>
      </div>
    </div>

    <div class="mb-4 row" v-if="showCertificate">
      <div class="col-auto">
        <div class="mb-4 qr">
          <img :src="certificate.certificateImage" alt="" />
        </div>
        <p><b>Действителен до&nbsp;&nbsp;&nbsp;</b>{{ formatedExpirationDate }}</p>
      </div>
      <div class="col">
        <p>Ваша ссылка для размещения сертификата</p>
        <b-form inline class="mb-4">
          <b-form-input v-model="certificate.bitlyUrl" type="text" id="bitly" class="mr-1" required></b-form-input>
          <b-button variant="secondary" @click="onCopy" type="button"><font-awesome-icon icon="copy"/></b-button>
        </b-form>

        <a :href="pdfHref" class="mr-2 btn btn-success">Загрузить</a>
        <b-button v-if="certificate" variant="success" @click="priviewCertificate">Просмотр</b-button>
      </div>
      <div class="col-auto pr-5" v-if="isManufacturer">Ваш менеджер {{ manager }}</div>
    </div>
    <div class="row">
      <div class="col">
        <h2 class="mb-4 h3">История сертификатов</h2>
        <div v-if="!certificateHistoryTotal">Нет истории сертификатов</div>
        <b-table
          v-if="certificateHistoryTotal"
          hover
          selectable
          select-mode="single"
          :items="certificatesHistory"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        ></b-table>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateFormat } from "@/helpers";

export default {
  name: "Certificates",
  props: {
    loading: Boolean,
    isManufacturer: Boolean,
    isDealer: Boolean,
    isSelfRetail: Boolean,
    isParentCertificateRevoked: Boolean,
    isCertificationProcess: Boolean,
    hasActiveRequest: Boolean,
    certificationRequests: Array,
    certificate: [Object, Array],
    certificatesHistory: Array,
    certificateHistoryTotal: Number,
    manager: [String, Object],
    pdfHref: String
  },
  data() {
    return {
      routePath: this.$route.path,
      sortBy: "dateOfIssue",
      sortDesc: true,
      newIssuanceCertificateRequest: null,
      fields: [
        { key: "dateOfIssue", label: "Дата", formatter: item => DateFormat.getDate(item) },
        { key: "expirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) },
        { key: "status", label: "Статус" }
      ],
      isShowManufacturerInfoInCertificate: false,
      isMounted: false
    };
  },
  methods: {
    priviewCertificate() {
      let route = (this.isManufacturer) ? this.$router.resolve(`/certificate?Key=${this.certificate.key}`)
                  : (this.isDealer) ?  this.$router.resolve(`/certificatedealer?Key=${this.certificate.key}`)
                  : (this.isSelfRetail) ? this.$router.resolve(`/certificateselfretail?Key=${this.certificate.key}`)
                  : null
      if (route) window.open(route.href, '_blank')
    },
    onCopy() {
      let copyText = document.getElementById("bitly");
      copyText.select();
      document.execCommand("copy");
    }
  },
  computed: {

    formatedExpirationDate() {
      return DateFormat.getDate(this.certificate.expirationDate);
    },

    showRevoked() {
      return this.isMounted && (Object.keys(this.certificate).length === 0 || this.certificate.isRevoked)
    },

    showCertificate() {
      return this.isMounted && (Object.keys(this.certificate).length > 0 && !this.certificate.isRevoked)
    }

  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.isMounted = true
    }, 300)
  },
};
</script>

<style lang="scss">
.qr {
  max-width: 300px;
  img {
    max-width: 100%;
  }
}
.switch-label {
  font-weight: bold;
}
</style>
