export const Messages = {
    Success: {
        UserAdded: 'Пользователь добавлен успешно.',
        UserUpdate: 'Пользователь отредактирован успешно.',
        UserDelete: 'Пользователь успешно удалён.',
        UserBlocked: 'Пользователь заблокирован успешно.',
        UserUnBlocked: 'Пользователь разблокирован успешно.',
        PasswordReseted: 'Пароль был сброшен успешно.',
        PasswordResetedByEmail: 'Сообщение со ссылкой на установку нового пароля успешно отправлено.',
        ManufacturerAdded: 'Производитель добавлен успешно.',
        ManufacturerDelete: 'Производитель успешно удален.',
        ManufacturerEdited: 'Данные производителя изменены успешно.',
        RequestEdited: 'Данные запроса изменены успешно.',
        PartnerDelete: 'Партнер успешно удален.',
        CertificateDelete: 'Сертификат успешно удален.',
        RequestDelete: 'Запрос успешно удален.',
        DataConfirmed: 'Данные подтверждены успешно.',
        MessageSended: 'Сообщение отправлено успешно.',
        DealerAdded: 'Партнер добавлен успешно',
        DealerEdited: 'Партнер отредактирован успешно',
        AddressEdited: 'Адрес отредактирован успешно',
        RequestConfirmed: 'Подтверждено успешно',
        RequestRejection: 'Отменено успешно',
        CertifyDealerRequest: 'Запрос на сертификацию отправлен успешно',
        SelfRetailDealerDelete: 'Офис партнера успешно удален.',
        SelfRetailAdded: 'Точка собственной розницы создана успешно',
        SelfRetailUpdate: 'Точка собственной розницы отредактирована успешно',
        SelfRetailDelete: 'Точка собственной розницы успешно удалена.',
        SelfRetailCertificateAdded: 'Запрос на сертификацию точки собственной розницы отправлен успешно',
        ApproveAddSelfRetail: 'Точка собственной розницы согласована',
        RejectAddSelfRetail: 'Отклонено согласование точки собственной розницы',
        ApproveCertificationSelfRetail: 'Выдан сертификат для точки собственной розницы',
        RejectCertificationSelfRetail: 'Отклонен сертификат для точки собственной розницы',
        RejectForCorrectionAddSelfRetail: 'Согласование точки собственной розницы отклонено на корректировку',
        EditRequestSelfRetail: 'Данные точки собственной розницы отредактированы',
        SelfRetailCertificateRevocation: 'Сертификат анулирован',
        ManufacturerCertifyReRelease: 'Продление сертификата произошло успешно'
    },
    Error: {
        TokenExpired: 'Время сеанса истекло!',
        TokenCreated: 'Токен авторизации не создался',
        UserAdded: 'Пользователь не был добавлен',
        UserUpdate: 'Пользователь не был отредактирован',
        BadRequest: 'Ошибка запроса. Попробуйте позже.',
        ManufacturerAdded: 'Производитель не был добавлен.',
        ManufacturerEdited: 'Ошибка редактирования.',
        RequestEdited: 'Ошибка редактирования.',
        DealerAdded: 'Партнер не был добавлен',
        DealerEdited: 'Ошибка редактирования.',
        AddressEdited: 'Ошибка редактирования.',
        RequestConfirmed: 'Ошибка подтверждения',
        RequestRejection: 'Ошибка отмены',
        CertifyDealerRequest: 'Запрос на сертификацию не был отправлен',
        MessageSended: 'Ошибка. Сообщение не отправлено.',
        SelfRetailAdded: 'Ошибка при создании точки собственной розницы',
        SelfRetailUpdate: 'Ошибка при редактировании точки собственной розницы',
        SelfRetailCertificateAdded: 'Ошибка при создании сертификата точки собственной розницы',
        ApproveAddSelfRetail: 'Ошибка при согласовании точки собственной розницы',
        RejectAddSelfRetail: 'Ошибка при отклонении согласования точки собственной розницы',
        ApproveCertificationSelfRetail: 'Ошибка при вВыдачи сертификата для точки собственной розницы',
        RejectCertificationSelfRetail: 'Ошибка отклонения сертификата для точки собственной розницы',
        RejectForCorrectionAddSelfRetail: 'Ошибка отклонения на корректировку при согласовании точки собственной розницы',
        EditRequestSelfRetail: 'Ошибка сохранения отредактированных данных точки собственной розницы',
        SelfRetailCertificateRevocation: 'Ошибка анулирования сертификата'
    },
    Warning: {
        Test: 'Hello it is warning'
    }
}