import RehauStore from "@/store/lib";
import { Role, Messages } from '@/constants';
import { AdminService } from "@/services";

const usersService = new AdminService()

const users = new RehauStore({
  state: {
    roles: [],
    total: null,
    checkPassToken: null,
    passCreated: null,
    messsage: "",
    users: [],
    usersTotal: null,

    leadersByRegionMessages: null,
  },
})
  .action("fetchRoles", {
    serviceFn: usersService.fetchRoles,
    property: "roles",
  })
  .action("fetchUsers", {
    serviceFn: usersService.fetchUsers,
    onSuccess: (state, { data }) => {
      state.users = data.items;
      state.usersTotal = data.totalItems;
    },
  })
  .action("addUser", {
    serviceFn: usersService.addUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.UserAdded, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("updateUser", {
    serviceFn: usersService.updateUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.UserUpdate, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("deleteUser", {
    serviceFn: usersService.deleteUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.UserDelete, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("blockUser", {
    serviceFn: usersService.blockUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.UserBlocked, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("unblockUser", {
    serviceFn: usersService.unblockUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.UserUnBlocked, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("resetPassword", {
    serviceFn: usersService.resetPassword,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.PasswordReseted, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("resetPasswordByEmail", {
    serviceFn: usersService.resetPasswordByEmail,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.PasswordResetedByEmail, { root: true });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  .action("checkLeadersByRegion", {
    serviceFn: usersService.checkLeadersByRegion,
    property: "leadersByRegionMessages",
  })
  .action("unblockCheck", {
    serviceFn: usersService.unblockCheck,
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, { root: true });
    },
  })
  // Getters
  .getter("rolesDD", (state) => {
    let res = state.roles.map((item) => {
      let role = Role.translate(item.caption);
      return { value: item.id, text: role };
    });
    //res.unshift({ value: null, text: "Роль" });
    return res;
  })
  .getter("editRolesDD", (state) => {
    let res = state.roles
      .filter((item) => {
        return item.caption !== Role.Manufacturer && item.caption !== Role.Dealer;
      })
      .map((item) => {
        let role = Role.translate(item.caption);
        return { value: item.id, text: role };
      });
    //res.unshift({ value: null, text: "Роль" });
    return res;
  })
  .getStore();

export default users;